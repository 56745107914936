

























import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class GuilderNavPage extends Vue {
   public windownWidth: number = document.body.clientWidth
   public loading: boolean = true
   public guildKey: number = -1
  @Watch('$store.state.main.screen.width') public windowHeight() {
    this.windownWidth = document.body.clientWidth
  }
   public headGuild(choseKey, pathTo) {
    if (choseKey !== this.guildKey) {
      this.$router.push({
        path: '/main/guider-nav-page/tables/' + pathTo,
      })
      this.guildKey = choseKey
    }
  }
  // VUE 生命周期
  private created() {
    setTimeout(() => {
      this.loading = !this.loading
    }, 500)
  }

}
