import { getStorageUserType } from '@/utils/util'
import { requestToken } from '../../../api/manage'

export function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

/**
 *
 * Person具体信息控制
 */

/**
 * 根据用户 ID 获取用户信息
 * @param {string} userId
 * @return {Promise}
 */
export function getPersonByUserId(userId) {
    return requestToken({
        url: `/api/v1/persons/${ getStorageUserType() }/getPerson/${ userId }`,
        method: 'get',
    })
}
