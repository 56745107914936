


































import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class Statistics extends Vue {
    public windownWidth = document.body.clientHeight
    public guildKey: number = 1
    @Watch('$store.state.main.screen.height') public windowWidth() {
        this.windownWidth = document.body.clientWidth
    }

    public headGuild(choseKey, pathTo) {
        if (choseKey !== this.guildKey) {
            this.$router.push({
                path: '/main/guilderNavPage/statistics/' + pathTo,
            })
            this.guildKey = choseKey
        }
    }
}
