






































































































































import { readRanterId } from '@/store/main/getters'
import _ from 'lodash'
import moment from 'moment'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { getTypeDeviceData } from '../../../../interfaces/service/device/deviceHardWare'
import { getBase64 } from '../../../../interfaces/service/person/personMang'
import { getAvatarUserData, getImageByPerson } from '../../../../interfaces/service/person/userAuthority'
import { getRecognizePersonsInfo } from '../../../../interfaces/service/recognize/rententPerson'
import { getRecognizeHistogram } from '../../../../interfaces/service/recognize/statusChart'
import { getDateByDateRange, getNow } from '../../../../utils/util'

import DeviceCretificStatus from '../../device/home_status/DeviceCretificStatus.vue'

@Component({
  components: {
    DeviceCretificStatus
  }
})
export default class Date extends Vue {
  public loading: boolean = true
  public recentUserDatas: any = []
  public theWindowH = document.body.clientHeight
  public dateRange: moment.unitOfTime.StartOf = 'day'
  public chartData: any = {
          columns: [],
          rows: []
        }
  public frequency: number = 0 // 当前时间下认证人数 （人次）
  public get legend() : any {
    return { bottom: '-20px' }
  }
  public get chartSettings() : any {
    return {
      yAxis: {
        axisLabel: {show: false},
        splitLine: {show: false},
        type : 'value',
      },
      series: {
        type: 'line',
        smooth: false
      }
    }
  }

  /**
   * 租户Id
   */
  public get ranterId(): number {
    let id: number = readRanterId(this.$store)
    if (id === -10) { id = +(sessionStorage.getItem('ranterId') || -10) }
    return id
  }

  /**
   * 当前时间
   */
  get currentTime(): string {
    return moment().format('HH:mm')
  }

  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }

  @Watch('dateRange')
  public async updateDateRange() {
    // 获取 chart 数据
    const chartData = await this.getChartData(this.ranterId)
    this.buildChartData(chartData)
    // 最近认证人员信息

    this.recentUserDatas =  await this.getRecentRecognizesUserData(this.ranterId)
    this.getFinalAvatar(this.recentUserDatas)
  }

  @Watch('ranterId')
  public updateRanterId() {
    if (this.ranterId === -10) { return }
    this.handleRequests()
  }

  public created() {
    this.handleRequests()
  }

  /**
   * 时间格式化
   * @param {Date} time 认证时间
   * @return {String} hh:mm 形式的时间字符串
   */
  public timeShowFormat(time) {
    return moment(time, 'yyyy.MM.dd HH:mm').format('HH:mm')
  }

  /**
   * 处理设备认证信息显示区域 日期选择下拉框 change 事件
   * @param {String} value 当前选中的值
   */
  public handleDateChange(value) {
    this.dateRange = value
  }

/**
 * 获取最近认证人员信息
 * @param {number} id orgId | ranterId
 */
  private async getRecentRecognizesUserData(id) {
    return getRecognizePersonsInfo(id, { end_time: getNow(), start_time: getDateByDateRange(this.dateRange) })
  }

  /**
   * 获取折线图数据
   */
  private async getChartData(id) {
    const config = {
      end_time: getNow(),
      start_time: getDateByDateRange(this.dateRange)
    }
    return getRecognizeHistogram(id, config)
  }

  /**
   * 构造折线图所需数据格式
   */
  private buildChartData(originData: any) {
    const textRecognizeNum = this.$i18n.tc('device.home.recognize_count')
    const rows: any[] = []

    // 保存当前时间下认证人数
    this.frequency = originData[0].recognize_num

    if (this.dateRange === 'day') {
      // 按日筛选，横坐标以小时为单位
      this.chartData.columns = ['hour', textRecognizeNum]
      originData.forEach((item, index) => {
        const obj: any = {}
        // 格式化小时 eg: 6 -> 06 | 12 -> 12
        const formatedHour = index.toString().length === 1 ? `0${ index }` : index
        obj['hour'] = `${ formatedHour }:00`

        obj[textRecognizeNum] = item.recognize_num
        rows.push(obj)
      })
    } else {
      // 按周或月进行筛选，横坐标以天为单位
      this.chartData.columns = ['day', textRecognizeNum]
      const reverseData = originData.reverse()

      // 将小时数据组按天分类
      const days = _.chunk(reverseData, 24)

      days.forEach((item: any, index: number) => {
        const obj: any = {}
        // 获取开始时间 MM-DD 形式
        const startOfDay: string = getDateByDateRange(this.dateRange, 'MM-DD')
        obj['day'] = moment(startOfDay, 'MM-DD').add(index, 'days').format('MM-DD')

        // 以天为单位计算认证人数
        const recognizeNum = item.reduce((prev: number, current: any) => {
          return prev + current['recognize_num']
        }, 0)

        obj[textRecognizeNum] = recognizeNum
        rows.push(obj)
      })
    }

    this.chartData.rows = rows
  }

  /**
   * 获取真实图片信息，并重新封装对象
   * @param {any[]} data 后台请求得到的用户数据
   */
  private getFinalAvatar(data) {
    if (data.length === 0) {
      return []
    }

    _.forIn(data, async (item) => {
      if (_.isEmpty(item.person_faces)) {
        return
      }
      await getImageByPerson(this.ranterId, item.person_faces[0].image_url)

      const url = await getBase64(this.$store.state.main.messagePro)
      this.$set(item, 'avatarUrl', url)
    })
  }

  /**
   * 处理该页面所有请求
   */
  private async handleRequests() {
    const id = this.ranterId

    await getAvatarUserData(this.$store.state.main.userProfile.user_type, id)
    await getTypeDeviceData(this.$store.state.main.userProfile.user_type, id)
    const chartData = await this.getChartData(id)
    this.buildChartData(chartData)

    this.recentUserDatas =  await this.getRecentRecognizesUserData(id)
    this.getFinalAvatar(this.recentUserDatas)
    this.loading = false
  }
}
