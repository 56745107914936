

















































































import { getUserById } from '@/interfaces/service/person/userAuthority'
import { readRanterId } from '@/store/main/getters'
import { getAllSubOrgIds } from '@/utils/util'
import _ from 'lodash'
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class NewAccountModal extends Vue {
  @Prop({ required: true, default: false }) public visible!: boolean // 是否显示modal
  @Prop({ required: true, default: -1 }) public type!: number // modal 类型 manager | superuser
  @Prop({ required: true, default: '' }) public title!: string // 展示title
  @Prop({ required: true, default: '' }) public mode!: string // 修改模式
  @Prop({ required: true, default: null }) public dataSource!: any // 选中的record

  public subLoading : boolean = false // 画面元素loading

  public form: any = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    org_ids: []
  }
  public ranterList: any = []
  public selectedRanterList: any = [] // 选中的所有租户列表
  public hiddenIds: any = [] // 选中的所有租户列表

  public replaceFields = {
    key: 'id',
    children: 'children',
    title: 'name',
    value: 'id',
    scopedSlots: 'scopedSlots',
  }

  /**
   * 租户Id
   */
  public get ranterId() : number {
    let id: number = readRanterId(this.$store)
    if (id === -10) { id = +(sessionStorage.getItem('ranterId') || -10) }
    return id
  }

  public get enterpriseData() : any[] {
    return this.$store.state.main.enterprise.enterpriseData
  }

  /**
   * modal 框高度
   */
  public get modalHeight(): string {
    return this.type === 0 ? '426px' : '500px'
  }

  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }

  public async created() {
    // 构建组织 select 数据
    const ranterList = this.enterpriseData.map((item) => {
      return {id: item.id, name: item.name}
    })

    this.ranterList = ranterList

    if (this.mode === 'new') {
      this.selectedRanterList = [this.ranterId]
      return
    }

    // 更新模式
    const userRes: any = await getUserById(this.dataSource.id)

    const { first_name, last_name, email, phone, orgs } = userRes

    // 构建组织 select 默认选中
    // let orgIds = orgs.map((org) => {
    let orgIds = this.dataSource.orgs.map((org) => {
      return org.org_id
    })

    orgIds = _.compact(orgIds)
    const ranterListIds = ranterList.map((item) => item.id)

    orgIds.map((id) => {
      if (ranterListIds.includes(id)) {
        this.selectedRanterList.push(id)
      } else {
        this.hiddenIds.push(id)
      }
    })

    this.form = {
      first_name,
      last_name,
      email,
      phone
    }
  }

  public mounted() {
    this.$nextTick(() => {
      (this.$refs.firstname as Vue & { focus: () => {}}).focus()
    })
  }

  /**
   * 确认
   */
  public handleOk() {
    this.form.org_ids = [this.selectedRanterList, this.hiddenIds]

    // 超管默认授予所有租户权限 传空值即可
    if (this.type === 0 ) { this.form.org_ids = [] }

    this.form.user_type = this.type
    this.$emit('ok', this.form)
  }

  /**
   * 返回
   */
  public handleCancle() {
    this.clearForm()
    this.$emit('cancle')
  }

  /**
   * 清空表单
   */
  public clearForm() {
      this.form = {
      firtst_name: '',
      last_name: '',
      email: '',
      phone: '',
      org_ids: []
    }
  }
}
