
































































































import { readRanterId } from '@/store/main/getters'
import _ from 'lodash'
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { inviteUserLink } from '../../../../interfaces/service/person/userAuthority'
import { commitSetLoading } from '../../../../store/main/mutations'
import { to } from '../../../../utils/util'

let id = 0
@Component
export default class NewAccountModal extends Vue {
  @Prop({ required: true, default: false }) public visible!: boolean // 是否显示modal
  @Prop({ required: true, default: -1 }) public type!: number // modal 类型 manager | superuser
  @Prop({ required: true, default: '' }) public title!: string // 展示title
  @Prop({ required: true, default: '' }) public mode!: string // 修改模式
  @Prop({ required: true, default: null }) public dataSource!: any // 选中的record

  public subLoading : boolean = false // 画面元素loading

  public formItemLayout: any = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
  }
  public formItemLayoutWithOutLabel: any = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    }
  }




  public showLink: any = false
  public linkList: any = []
  public form: any = {}
  public ranterList: any = []
  public replaceFields = {
    key: 'id',
    children: 'children',
    title: 'name',
    value: 'id',
    scopedSlots: 'scopedSlots',
  }

  /**
   * 租户Id
   */
  public get ranterId() : number {
    let idx: number = readRanterId(this.$store)
    if (idx === -10) { idx = +(sessionStorage.getItem('ranterId') || -10) }
    return idx
  }

  public get enterpriseData() : any[] {
    return this.$store.state.main.enterprise.enterpriseData
  }

  /**
   * modal 框高度
   */
  public get modalHeight(): string {
    return this.type === 0 ? '426px' : '500px'
  }

  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }

  public async created() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' })
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
  }

  public mounted() {
    // this.$nextTick(() => {
    //   (this.$refs.firstname as Vue & { focus: () => {}}).focus()
    // })
    this.addEmailFiled()
  }

  /**
   * 确认
   */
  public handleOk(type) {
    this.form.user_type = this.type
    this.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values
        const emails = _.uniqWith( keys.map((key) => names[key]), _.isEqual)
        if (type === 'email') {
          this.sendEmail(emails)
        } else if (type === 'link') {
          this.getLink(emails)
        }
      }
    })
  }

  /**
   * 发送邮件
   */
  public sendEmail(emails) {
    this.$emit('ok', emails)
  }

  /**
   * 获取邀请链接
   */
  public async getLink(data) {
    if (!data || data.length === 0 ) { return }
    commitSetLoading(this.$store, true)
    try {
      const orgId = this.ranterId
      const emails = {
        emails: data
      }
      const [err, res] = await to(inviteUserLink(orgId, emails))
      if (err) {
        this.$message.error(this.$i18n.tc('summary.validation.invite.fail'))
      } else {
        this.showLink = true
        this.linkList = res
      }
    } catch (e) {
      console.log(e)
    }
    commitSetLoading(this.$store, false)
  }

  /**
   * 返回
   */
  public handleCancle() {
    this.clearForm()
    this.$emit('cancle')
  }

  /**
   * 清空表单
   */
  public clearForm() {
    id = 0
  }


  public remove(k) {
      const { form } = this
      const keys = form.getFieldValue('keys')
      if (keys.length === 1) {
        return
      }
      form.setFieldsValue({
        keys: keys.filter((key) => key !== k)
      })
    }

  public  addEmailFiled() {
      const { form } = this
      const keys = form.getFieldValue('keys')
      if (keys.length >= 5) {
        this.$message.error(this.$i18n.tc('summary.acount.email.new.max'))
        return
      }
      const nextKeys = keys.concat(id++)
      form.setFieldsValue({
        keys: nextKeys,
      })
  }

  public copyUrl(shareLink) {
    const input = document.createElement('input')   // 直接构建input
    input.value = shareLink  // 设置内容
    document.body.appendChild(input)    // 添加临时实例
    input.select()   // 选择实例内容
    document.execCommand('Copy')   // 执行复制
    document.body.removeChild(input) // 删除临时实例
  }
}
