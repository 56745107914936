


























































import { readRanterId } from '@/store/main/getters'
import _ from 'lodash'
import { Vue, Prop, Component } from 'vue-property-decorator'
import { deleteOneUser, getUsers, updateUserOrg } from '../../../../interfaces/service/person/userAuthority'
import { getGlobalUserType, to } from '../../../../utils/util'

@Component({
  data() {
    return {
      rowSelection: {
        selectedRowKeys: [],
        onChange: (selectedRowKeys, selectedRows) => {
          this.$data.rowSelection.selectedRowKeys = selectedRowKeys
          this.$emit('onChange', selectedRowKeys, selectedRows)
        },
        onSelect: (record, selected, selectedRows) => {
          this.$emit('onSelect', record, selected, selectedRows)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          this.$emit('onSelectAll', selected, selectedRows, changeRows)
        }
      }
    }
  },
  computed: {
    columns() {
      const colunms = [{
      title: this.$i18n.tc('summary.table.column.acount'),
      dataIndex: 'login',
      key: 'login',
      ellipsis: true,
    },
    {
      title: this.$i18n.tc('summary.table.column.firstName'),
      dataIndex: 'first_name',
      key: 'first_name',
      ellipsis: true
    },
    {
      title: this.$i18n.tc('summary.table.column.lastName'),
      dataIndex: 'last_name',
      key: 'last_name',
      ellipsis: true
    },
    {
      title: this.$i18n.tc('summary.table.column.email'),
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: this.$i18n.tc('summary.table.column.phone'),
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      scopedSlots: { customRender: 'phone' },
    },
    {
      title: this.$i18n.tc('summary.table.column.action'),
      dataIndex: 'action',
      key: 'action',
      width: 150,
      align: 'center',
      scopedSlots: { customRender: 'action' },
      }]
      // 只有管理员显示组织
      if ((this as Vue & {type: string}).type === 'manager') {
      colunms.splice(4, 0, {
        title: this.$i18n.tc('summary.table.column.groups'),
        dataIndex: 'orgs',
        key: 'orgs',
        ellipsis: true,
        scopedSlots: { customRender: 'groups' }
      })
      }
      return colunms
      }
    }
  })
export default class AuthorityAccountList extends Vue {
  @Prop({ required: true, default: '' }) public type!: string // 管理员表和超管表的区分 manager | superuser

  public theWindowH = document.body.clientHeight
  /** ---------------  Table ----------------- */
  public data: any = [] // 表格data

  /** ---------------  DelteMenu ----------------- */
  public deleteModalVisible: boolean = false
  public deleteRecord: any = {}

  /**
   * 租户ID
   */
  public get ranterId() : number {
    let id: number = readRanterId(this.$store)
    if (id === -10) { id = +(sessionStorage.getItem('ranterId') || -10) }
    return id
  }

  public get scrollFix(): string {
    // 判断是否出滚动条
    const scrollY = this.theWindowH * 0.64 - 50
    const cellHeight = 52

    if (scrollY < this.data.length * cellHeight) {
      return 'header-srcoll--show'
    }

    return 'header-srcoll--hidden'
  }

  /**
   * created
   */
  public async created() {
    this.handleRequests()

    window.addEventListener('resize', _.debounce(this.handleWindowResize, 200))
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  /**
   * getUsers
   */
  public async handleRequests() {
    const data: any =  await getUsers()
    _.uniqWith(data, _.isEqual)

    // 根据对应的用户 type 筛选数据
    this.data = data.filter((item) => {
      let isShow: boolean = true
      if (item.user_type === 1) {
        // 管理员账户是否有权限访问该租户
        isShow = item.orgs.some((org) => org.org_id === this.ranterId)
      }
      return getGlobalUserType(item.user_type) === this.type && isShow
    })
    this.$emit('onDataChange', this.data)
    // 重置 table 选中
    this.$data.rowSelection.selectedRowKeys = []
  }

  /**
   * 编辑操作
   */
  public handleEdit(record) {
    this.$emit('onEdit', record)
  }

  /**
   * 删除操作
   */
  public handleDelete(record) {
    this.deleteRecord = record
    this.deleteModalVisible = true
  }

  /**
   * deleteModalOk
   */
  public async deleteModalOk() {
    // 不能删除自己
    const userId = this.$store.state.main.userProfile.id
    if (this.deleteRecord.id === userId) {
      this.deleteModalVisible = false
      this.$message.error(this.$i18n.tc('summary.validation.delete_user'))
      return
    }

    // 被删除用户是否存在其他绑定组织, 存在则只解除当前组织绑定
    const { id, orgs } = this.deleteRecord

    let restOrgIds = orgs.map((item) => {
      if (item.org_id !== this.ranterId) {
        return item.org_id
      }
    })

    restOrgIds = _.compact(restOrgIds)

    // 存在其他绑定组织 解除当前组织绑定
    if (restOrgIds.length > 0) {
      const [err] = await to(updateUserOrg(userId, restOrgIds))

      if (err) {
        return
      }
      await this.handleRequests()
      return
    }

    // 不存在其他绑定组织 删除用户
    await deleteOneUser(id)
    this.deleteModalVisible = false
    // 重新获取用户信息
    this.handleRequests()
  }

  /**
   * deleteModalCancle
   */
  public deleteModalCancle() {
    this.deleteModalVisible = false
  }

  private handleWindowResize(e) {
    this.theWindowH = document.body.clientHeight
  }
}
