
































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import {
  searchRecognizeLog,
  downloadRecognizeLog,
  getLogFile
} from '../../../interfaces/service/recognize/deviceRecognize'
import {
  getRanterTreeData
} from '../../../interfaces/service/org/enterpriseMang'
import { getBase64 } from '../../../interfaces/service/person/personMang'
import _, { isLength, lte, parseInt } from 'lodash'
import moment from 'moment'
import GuilderHeadButton from '../../../views/main/bottom_guild/GuilderHeadButton.vue'
import svgDownload from '../../../components/svg_saves/user_authority_show/Download.vue'
import {
  getDeviceDataIntable
} from '../../../interfaces/service/device/deviceHardWare'
import {
  getPersonBranchORG
} from '../../../interfaces/service/person/userAuthority'
import defaultImg from '@/assets/image.png'

@Component({
  components: {GuilderHeadButton, svgDownload },
  data() {
    return {
      svgDownload,
      form: this.$form.createForm(this),
      editable: false,
      // 表格edit状态
      searchText: '',
      searchedColumn: null,
      searchInput: null
    }
  },
  methods: {
  },
  computed: {
  },
})
export default class LogListShow extends Vue {
  public $refs!: {table: HTMLFormElement, code: HTMLFormElement, name: HTMLFormElement}
  public scrollFix: string = ''
  public tableData: any[] = []
  public tableDataCache: any[] = [] // 表格数缓存，供搜索使用
  public theWindowW = document.body.clientWidth
  public loading: boolean = true
  public userType: number = this.$store.state.main.userProfile.user_type
  // ------弹出对话框-----------
  public visible: boolean = false
  public confirmLoading: boolean = false
  public csvUploadfilelist: any = []
  // ------弹出对话框-----------
  // 表格分页，过滤，排序情况
  public thePagination: any = null
  public theFilters: any = null
  public theSorter: any = { order: null, field: null }
  // 展开tree时触发事件
  public ranterIDCose = Number(sessionStorage.getItem('ranterId'))
  public expandedRowKeys = [this.ranterIDCose]
  public dataResult: any = {
    items: [],
    total_num: 0
  }
  public currentPage: number = 1
  public pageSize: number = 8
  public resultWidth: number = 1120

  public resetConformFlag: boolean = false
  public showDeletOrgNames: string[] = []


  public orgList: any = []
  public deviceList: any = []
  public personList: any = []
  public deviceListT: any = []
  public personListT: any = []
  public form: any = null
  public defaultSearch: any = {
        org_id: null,
        device_id: null,
        person_id: null,
        time_zone: null,
        start_time: moment(new Date(), 'YYYY-MM-DD'),
        end_time: null
  }

  public logImg: any = {}
  // delete row selected
  // VUE 生命周期
  private async created() {
    const enterpriseData: any = await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    this.defaultSearch.org_id = this.ranterIDCose
    this.orgList = []
    this.generateOrgList(enterpriseData, '')
    this.handleSubmit(null)
    // deviceList
    await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.ranterIDCose)
    this.deviceList = this.$store.state.main.deviceHardWare.deviceData
    await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.ranterIDCose)
    this.personList = this.$store.state.main.userAuthorityShow.userData
    this.changeOrg(this.ranterIDCose)
  }

  public changeOrg(oId) {
    const deviceListT: any = []
    const personListT: any = []
    this.deviceList.forEach((dev) => {
      const orgIds = dev.org_ids
      orgIds.forEach((org) => {
        if (org.id === oId) {
          deviceListT.push(dev)
        }
      })
    })
    this.personList.forEach((per) => {
      const orgIds = per.org_ids
      orgIds.forEach((org) => {
        if (org.id === oId) {
          personListT.push(per)
        }
      })
    })
    this.personListT = personListT
    this.deviceListT = deviceListT
  }

  @Watch('$store.state.main.screen.width') public windowHeight() {
    this.theWindowW = document.body.clientWidth
    this.changeLogDataWidth()
  }

  public changeSubmitData(data) {
    data.start_time = data.start_time ? moment(data.start_time).format('YYYY-MM-DD') + ' 00:00:00' : null
    data.end_time = data.end_time ? moment(data.end_time).format('YYYY-MM-DD') + ' 23:59:59' : null
    data.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    data.file_flag = false
    return data
  }

  public csvFilesOut() {
    this.form.validateFields((err, values) => {
        if (!err) {
          const data = this.changeSubmitData(values)
          data.file_flag = true
          this.downloadFile(data)
        }
    })
  }
  public async downloadFile(data) {
      await downloadRecognizeLog(data)
      // 定义文件内容，类型必须为Blob 否则createObjectURL会报错
      const content = new Blob([await this.$store.state.main.messagePro])
      // 生成url对象
      const urlObject: any = window.URL || window.webkitURL || window
      const url = urlObject.createObjectURL(content)
      // 生成<a></a>DOM元素
      const el = document.createElement('a')
      // 链接赋值
      el.href = url
      el.download = 'device_log.xlsx'
      // 必须点击否则不会下载
      el.click()
      // 移除链接释放资源
      urlObject.revokeObjectURL(url)
  }

  public handleSubmit(e) {
    if (e) {
      e.preventDefault()
      this.currentPage = 1
    }
    this.form.validateFields((err, values) => {
        if (!err) {
          if (!values.org_id) {
            values.org_id = this.ranterIDCose
          }
          values.limit = this.pageSize
          values.page = this.currentPage
          const data = this.changeSubmitData(values)
          data.file_flag = false
          this.searchLog(data)
        }
    })
  }

  public async searchLog(data) {
    const result =  await searchRecognizeLog(data)
    this.dataResult = result
    const logImg = {}
    _.forEach(this.dataResult.items, (item) => {
      logImg[item.msg_id] = defaultImg
    })
    this.logImg = logImg
    this.downloadLogFile()
  }

  public downloadLogFile() {
    _.forEach(this.dataResult.items, (item) => {
      getLogFile(this.ranterIDCose, item.image).then((data) => {
        getBase64(data).then((d) => {
          this.logImg[item.msg_id] = d
        })
      }).catch((err) => {
        console.log(err)
      })
    })
  }

  public resetForm() {
    this.form.resetFields()
    this.changeOrg(this.ranterIDCose)
    this.currentPage = 1
    this.handleSubmit(null)
  }

  public onChangePage(current) {
    this.currentPage = current
    this.handleSubmit(null)
  }

  public generateOrgList(data, space) {
    for (const i of data) {
      const node: any = i
      this.orgList.push({ id: node.id, name: space + ' ' + node.name })
      if (node.children) {
        this.generateOrgList(node.children, space + ' ')
      }
    }
  }

  public mounted() {
    this.changeLogDataWidth()
  }
  public changeLogDataWidth() {
    if (this.theWindowW <= 1440) {
       this.pageSize = 8
       this.resultWidth = 1120
    } else if (this.theWindowW < 1920) {
      this.pageSize = 10
      this.resultWidth = 1400
    } else if (this.theWindowW >= 1920) {
      this.pageSize = 12
      this.resultWidth = 1680
    }
  }
}
