






























































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import VGrid from '@revolist/vue-datagrid'
import {
  getPersonBranchORG,
  // deleteAll,
  logicalDeleteAll,
  updateUserInfo,
  downloadDataZip,
  personInfoCover
} from '../../../interfaces/service/person/userAuthority'
import {
  getRanterTreeData,
} from '../../../interfaces/service/org/enterpriseMang'
import { commitSetLoading } from '../../../store/main/mutations'
import csvUpModal from '../other/CsvUploadModal.vue'
import drawerUser from '../user/UserAddModifyDraw.vue'
import _ from 'lodash'
import SelectTypePlugin from '@revolist/revogrid-column-select'
import guildHeadBut from '../../../views/main/bottom_guild/GuilderHeadButton.vue'
import svgLock from '../../../components/svg_saves/user_authority_show/Lock.vue'
import svgUnlock from '../../../components/svg_saves/user_authority_show/Unlock.vue'
import svgDelete from '../../../components/svg_saves/user_authority_show/Delete.vue'
import svgDownload from '../../../components/svg_saves/user_authority_show/Download.vue'
import svgUpload from '../../../components/svg_saves/user_authority_show/UpLoad.vue'
const certificateArry : any = [
          {label : '', value : ''},
          {label : window['vm'].$i18n.tc('user.authority.certificate.idCard'), value : '111'},
          {label : window['vm'].$i18n.tc('user.authority.certificate.accountBook'), value : '113'},
          {label : window['vm'].$i18n.tc('user.authority.certificate.officeId'), value : '114'},
          {label : window['vm'].$i18n.tc('user.authority.certificate.workPermit'), value : '131'},
          {label : window['vm'].$i18n.tc('user.authority.certificate.driverLicense'), value : '335'},
          {label : window['vm'].$i18n.tc('user.authority.certificate.passport'), value : '414'},
          {label : window['vm'].$i18n.tc('user.authority.certificate.citizenCard'), value : '782'},
          {label : window['vm'].$i18n.tc('user.authority.certificate.otherLicense'), value : '990'}
        ]
@Component({
  components: {
    VGrid,
    drawerUser,
    svgLock,
    svgUnlock,
    svgDownload,
    svgDelete,
    guildHeadBut,
    csvUpModal,
  },
  data() {
    return {
      svgLock,
      svgUnlock,
      svgDownload,
      svgDelete,
      svgUpload,
      select: new SelectTypePlugin()
    }
  },
  methods: {
    details(param) {
      // 将点击过的链接存入顶部tags
      let headTags: any = this.$store.state.main.headTagsave
      if (headTags) {
        headTags.push({
          tagName: this.$i18n.tc('user.authority.person_management') + 'ID:' + param,
          name: 'UserDetail',
          params: { id: param },
        })
      } else {
        headTags = [
          {
            tagName: this.$i18n.tc('user.authority.person_management') + '_ID:' + param,
            name: 'UserDetail',
            params: { id: param },
          }
        ]
      }

      this.$store.state.main.headTagsave = _.uniqWith(headTags, _.isEqual)
      // 跳转
      this.$router.push({ name: 'UserDetail', params: { id: param } })
    },
  },
})
export default class UserAuthorityShow extends Vue {
  public theRanterIDs = sessionStorage.getItem('ranterId')
  public theWindowH = document.body.clientHeight
  public userType: number = this.$store.state.main.userProfile.user_type
  public tableEditModeOff: boolean = true
  public downloadOff: boolean = true
  // ------弹出对话框-----------
  public modelVisible: boolean = false
  public confirmLoading: boolean = false
  public fileList: any = []
  // ------弹出对话框-----------
  // 绑定人员到设备弹窗
  public deviceDetailDrawFlag: boolean = false
  public userDetaildata: any = null
  // TABLE ACTION 表格操作
  public editConformFlag: boolean = false
  public resetConformFlag: boolean = false
  // 表格 edit 后用于向后台提交的 list，也用于显示更新的项目
  public afterTableEditDataShow: any = []
  public usersDataLength: number = -1 // user统计
   // table edit Mode 表格编辑模式开关
  public tableSaveFlag: string = ''
  // CSV导入方式
  public csvUpLoadModelShow: boolean = false // 显示上传model
  public selected: any = []
  // 表格list 显示的数据源
  public getUserListData : any = []
  // csv文件上传时，返回的list
  public csvFileList : any = []
  // csv覆盖更新FLAG
  public csvCoverUpdate : boolean = false
  // 表格check消息提示
  public warrningMEssage : any[] = []
  // 保存并锁定flag
  public saveLockFlag : boolean = false
  // 待删除的列 idlist
  public deleteIDList : any = []
  // 初始化的原始数据
  public userSourceData : any = []
  // 表格排序字段，以及顺序
  public sortProps : string = ''
  public sortRule : string = ''
  // 组织树结构
  public treeEnterprise : any
  public filtercontains: any = (cellValue, extraValue) => {
    if (!cellValue) {
      return false
    }
    if (typeof cellValue !== 'string') {
      cellValue = JSON.stringify(cellValue)
    }
    if (extraValue) {
      return cellValue.indexOf(extraValue) > -1 ? cellValue : null
    } else {
      return cellValue
    }
  }
   public filterGt: any = (cellValue, extraValue) => {
    if (!cellValue) {
      return false
    }
    if (typeof cellValue !== 'string') {
      cellValue = JSON.stringify(cellValue)
    }
    if (extraValue) {
      return cellValue > parseInt(extraValue, 10) ? cellValue : null
    } else {
      return cellValue
    }
  }
    // table filter contains actions
  public filterGenderM: any = (cellValue) => {
    // 男
    return cellValue === '1' ? cellValue : null
  }
  public filterGenderF: any = (cellValue) => {
    // 女
    return cellValue === '2' ? cellValue : null
  }
  public filterGenderO: any = (cellValue) => {
    // 未知
    return cellValue === '0' ? cellValue : null
  }
  public filterConfig: any = {
    xx: this,
    collection: {
      name: {
        type: 'contains',
      },
    },
    include: ['contains', 'agecontains', 'ageGt', 'gendersM', 'gendersF', 'gendersO', 'none'],
    customFilters: {
      contains: {
        columnFilterType: 'mycontains', // column filter type id
        name: this.$parent.$i18n.tc('user.authority.keyword_search'),
         // @ts-ignore
        func: this.filtercontains,
      },
      agecontains: {
        columnFilterType: 'ages',
        name: this.$parent.$i18n.tc('user.authority.keyword_search'),
        // @ts-ignore
        func: this.filterGt,
      },
      ageGt: {
        columnFilterType: 'ages',
        name: this.$parent.$i18n.tc('user.authority.gt'),
        // @ts-ignore
        func: this.filterGt,
      },
      gendersM: {
        columnFilterType: 'genders',
        name: this.$parent.$i18n.tc('user.authority.sex_m'),
        // @ts-ignore
        func: this.filterGenderM,
      },
      gendersF: {
        columnFilterType: 'genders',
        name: this.$parent.$i18n.tc('user.authority.sex_f'),
        // @ts-ignore
        func: this.filterGenderF,
      },
      gendersO: {
        columnFilterType: 'genders',
        name: this.$parent.$i18n.tc('user.authority.sex_o'),
        // @ts-ignore
        func: this.filterGenderO,
      },
      none: {
        name: this.$parent.$i18n.tc('clear'),
        func: () => true,
      },
    },
  }
  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.confirmLoading = this.$store.state.main.waitloading
  }
  @Watch('$store.state.main.userAuthorityShow.userData')
  public userDataWatch(value) {
    this.downloadOff = value.length === 0
  }
  @Watch('$route') public async refreshUserData() {
    this.filterGt.extra = 'input'
    this.filtercontains.extra = 'input'
    this.theRanterIDs = sessionStorage.getItem('ranterId')
    await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
    this.userSourceData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
    this.usersDataLength = this.$store.state.main.userAuthorityShow.userData.length
    this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
  }
  // TABLE ACTION 表格操作 END
  // 性别下拉菜单
  get tableColumns() {
    const indexShow = {
      size: 80,
      filter: false,
      columnTemplate: (createElement, props) => {
        return createElement('button', {
          onclick: () => {
           // this.tableEditModeOff = !this.tableEditModeOff 解锁编辑模式
            if (!this.tableEditModeOff) {
              this.tableSaveFlag = 'person'
            } else {
              this.tableSaveFlag = ''
            }
          },
          style: {
            height: '30px',
            width: '30px',
            margin: '15px 0 0 0',
          },
          class: 'lockEdit',
        })
      },
      cellTemplate: (createElement, props) => {
        return [
          createElement('button', {
            onclick: () => {
              // TODO
            },
            rowIndex: props.rowIndex,
            style: {
              height: '20px',
              width: '20px',
              marginTop: '5px',
              paddingLeft: '15px',
              paddingBottom: '6px',
            },
            class: 'lockEdit',
          }),
          props.rowIndex + 1
        ]
      },
    }
    const checkBoxShow = {
      name: this.$i18n.tc('select_all'),
      type: 'checkbox',
      colSize: 20,
      filter: false,
      readonly: true,
      disable: this.tableEditModeOff,
      size: 80,
      columnTemplate: (createElement, props) => {
        return [
          createElement('input', {
            onclick: () => {
              this.resetSelectAllCheckBox(null)
              // const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxItem')
              // if (this.selected.length >= this.usersDataLength) {
              //   this.selected = []
              //   itemCheck.forEach((obj) => {
              //     obj['checked'] = false
              //   })
              // } else {
              //   this.selected = this.getUserListData
              //   itemCheck.forEach((obj) => {
              //     obj['checked'] = true
              //   })
              // }
            },
            type: 'checkbox',
            class: 'checkBoxAll',
            style: {
              position: 'fixed',
              border: 'none',
              background: 'none',
              fontWeight: 'bold',
              marginTop: '16px',
            },
          }),
          createElement(
            'span',
            { style: { position: 'fixed', left: '30px' } },
            this.$i18n.tc('select_all')
          )
        ]
      },
      cellTemplate: (createElement, props) => {
        return [createElement('input', {
          onchange: (e: any) => {
            // if (e.target.checked) {
            //   this.selected.push(props.data[props.rowIndex])
            // } else {
            //   this.selected = _.pull(this.selected, props.data[props.rowIndex])
            // }
          },
          checked: false, // _.indexOf(this.selected, props.data[props.rowIndex]) > -1,
          type: 'checkbox',
          style: {
            color: '#00B239',
            height: '13px',
            width: '13px',
            marginTop: '5px',
          },
          class: 'checkBoxItem',
          rIdx: props.rowIndex
        }), ' ' + (props.rowIndex + 1)
        ]
      },
    }
    return [
      this.tableEditModeOff ? indexShow : checkBoxShow,
      {
        name: '-',
        prop: 'rowKey',
        filter: false,
        size: 1,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              display: 'none',
            }
          }
        }
      },
      {
        prop: 'name',
        filter: 'mycontains',
        editor: 'nameEdit',
        sortable: true,
        order: 'asc',
        size: 120,
        columnTemplate: (createElement, props) => {
          return createElement('span', {
            class: 'title_require'
          }, this.$i18n.tc('user.authority.name'))
        },
      },
      {
        name: this.$i18n.tc('user.authority.person_type'),
        prop: 'person_type',
        filter: 'mycontains',
        size: 140,
        sortable: true,
      },
      {
        labelKey: 'label',
        valueKey: 'value',
        source: [
          {label : this.$i18n.tc('user.authority.sex_m'), value : '1'},
          {label : this.$i18n.tc('user.authority.sex_f'), value : '2'},
          {label : this.$i18n.tc('user.authority.sex_o'), value : '0'}
        ],
        columnType: 'select',
        name: this.$i18n.tc('user.authority.gender'),
        prop: 'gender',
        filter: 'genders',
        sortable: true,
        size: 82,
      },
      {
        name: this.$i18n.tc('user.authority.age'),
        prop: 'age',
        filter: 'ages',
        columnType: 'numeric',
        sortable: true,
        size: 82,
      },
      {
        // labelKey: 'label',
        // valueKey: 'value',
        size: 124,
        // source: certificateArry,
        // columnType: 'select',
        prop: 'certificate_type',
        filter: 'certificate_type',
        sortable: true,
        columnTemplate: (createElement, props) => {
          return createElement('span', {
            class: 'title_require'
          }, this.$i18n.tc('user.authority.certificate.type'))
        },
        cellTemplate: (createElement, props) => {
          let certificate_type = props.model.certificate_type
          if (certificate_type) {
            certificateArry.map((obj, index) => {
              if (obj.value === props.model.certificate_type) {
                certificate_type = obj.label
              }
            })
          } else {
            certificate_type = this.$i18n.tc('user.authority.certificate.otherLicense')
          }
          return createElement('span', {}, certificate_type)
        }
      },
      {
        prop: 'certificate_number',
        filter: 'mycontains',
        size: 124,
        sortable: true,
        columnTemplate: (createElement, props) => {
          return createElement('span', {
            class: 'title_require'
          }, this.$i18n.tc('user.authority.certificate.number'))
        }
      },
      {
        name: this.$i18n.tc('user.authority.phone'),
        prop: 'phone',
        filter: 'mycontains',
        size: 110,
        sortable: true,
      },
      {
        name: this.$i18n.tc('user.authority.email'),
        prop: 'email',
        filter: 'mycontains',
        size: 120,
        sortable: true,
      },
      {
        name: this.$i18n.tc('user.authority.org'),
        readonly: true,
        prop: 'orgs_name',
        filter: 'mycontains',
        sortable: true,
        size: 150,
        cellTemplate: (createElement, props) => {
          return createElement(
            'span',
            {style: {color: '#b8b8b8'}},
            props.model.orgs_name
          )
        }
      },
      {
        name: this.$i18n.tc('user.authority.created_by'),
        readonly: true,
        prop: 'created_by',
        filter: 'mycontains',
        size: 140,
        columnType: 'date',
        sortable: true,
        cellTemplate: (createElement, props) => {
          return createElement(
            'span',
            {style: {color: '#b8b8b8'}},
            props.model.created_by
          )
        }
      },
      {
        name: this.$i18n.tc('user.authority.last_modified_by'),
        readonly: true,
        prop: 'last_modified_by',
        filter: 'myFilterType',
        size: 140,
        sortable: true,
        cellTemplate: (createElement, props) => {
          return createElement(
            'span',
            {style: {color: '#b8b8b8'}},
            props.model.last_modified_by
          )
        }
      },
      {
        filter: false,
        readonly: true,
        size: 160,
        pin: 'colPinEnd',
        cellTemplate: (createElement, props) => {
          return [
            createElement(
              'button',
              {
                onclick: () => {
                  // if (props.data[props.rowIndex].id) {
                  //   this.userDetaildata = props.data[props.rowIndex]
                  //   this.deviceDetailDrawFlag = true
                  // }
                  const choseData = this.getRealUserChoseDetail(props.rowIndex)
                  if (choseData.id) {
                    this.userDetaildata = choseData
                    this.deviceDetailDrawFlag = true
                  }
                },
                style: {
                  display : this.tableEditModeOff ? 'block' : 'none',
                  width: '100%',
                  height: '32px',
                  color: '#00B239',
                  border: 'none',
                  backgroundColor: '#D6FFC2',
                  borderRadius: '5px',
                  textAlign: 'center',
                  borderTop: '1px solid #fff',
                  borderBottom: '1px solid #fff',
                },
              },
              this.$i18n.tc('user.authority.bind')
            )
          ]
        },
      }
    ]
  }
  public async afterTableEdit(data) {
    const theSource = this.userSourceData
    // flag为false时是edit模式
    if (this.tableEditModeOff) {
      this.$message.error(this.$i18n.tc('user.authority.use_edit_mode_msg'))
      await  getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
      this.getUserListData = this.$store.state.main.userAuthorityShow.userData
    } else {
      // const targetEditData = _.cloneDeep(data.detail.models || { 0: data.detail.model })
      const afterEditSaveZone = this.afterTableEditDataShow
      if (data.detail.models) {
        // 多行修改
        const targetEditData = _.cloneDeep(await data.detail.models)
        _.forIn(targetEditData, (e : any , key : any) => {
        // 验证
          const souceData = _.valuesIn( _.find(theSource, (o : any) =>
           o.rowKey === targetEditData[key].rowKey))
          const updateData =  _.valuesIn(targetEditData[key])
          if ( _.difference(updateData, souceData).length > 2 ||
          _.difference(souceData,  updateData).length > 2) {
          this.checkUpdateVal(data.detail, key, true)
          _.remove(afterEditSaveZone, (o : any) => e.rowKey === o.rowKey)
          afterEditSaveZone.push(targetEditData[key])
          this.$store.state.main.userAuthorityShow.hadEditFlag = true
          } else {
            // 与原始数据完全一样不做更新
            _.remove(afterEditSaveZone, (o : any) => e.rowKey === o.rowKey)
            if (afterEditSaveZone.length === 0) {
               this.$store.state.main.userAuthorityShow.hadEditFlag = false
            }
          }
        })
      } else {
        // 单条修改
        const targetEditData = _.cloneDeep(data.detail.model)
        const souceData = _.valuesIn(_.find(theSource, (o : any) =>
        o.rowKey === targetEditData.rowKey))
        if (_.difference(_.valuesIn(targetEditData), souceData).length > 2  ||
        _.difference(souceData,  _.valuesIn(targetEditData)).length > 2) {
           this.checkUpdateVal(data.detail || '', 0, true)
           _.remove(afterEditSaveZone, (o : any) => targetEditData.rowKey === o.rowKey)
           afterEditSaveZone.push(targetEditData)
           this.$store.state.main.userAuthorityShow.hadEditFlag = true
        } else {
          // 与原始数据完全一样不做更新
          _.remove(afterEditSaveZone, (o : any) => targetEditData.rowKey === o.rowKey)
          if (afterEditSaveZone.length === 0) {
            this.$store.state.main.userAuthorityShow.hadEditFlag = false
          }
        }
      }
      this.afterTableEditDataShow = [...afterEditSaveZone]
      this.warrningMEssage.forEach( (message : string) => {
         this.$message.error(this.$i18n.tc(message))
      })
      this.warrningMEssage = []
    }
  }
  public  checkUpdateVal(data, key, flag) {
        if (data.data) {
          Object.keys(data.data[key]).forEach((element : any) => {
            switch (element) {
              case 'name':
                this.checkValFun('name', data.data[key].name || '') ? flag = flag : flag = false
                break
              case 'person_type':
                this.checkValFun('person_type', data.data[key].person_type || '') ? flag = flag : flag = false
                break
              case 'person_number':
                this.checkValFun('person_number', data.data[key].person_number || '') ? flag = flag : flag = false
                break
              case 'age':
                this.checkValFun('age', data.data[key].age ) ? flag = flag : flag = false
                break
              case 'phone':
                this.checkValFun('phone', data.data[key].phone ) ? flag = flag : flag = false
                break
              case 'certificate_type':
                this.checkValFun('certificate_type', data.data[key].certificate_type ) ? flag = flag : flag = false
                break
              case 'certificate_number':
                this.checkValFun('certificate_number', data.data[key].certificate_number ) ? flag = flag : flag = false
                break
            }
          })
        } else {
              this.checkValFun(data.prop, data.val || '' ) ? flag = flag : flag = false
            }
        return  flag
  }
  public checkValFun(checkItem , checkValue ) {
    let flag : boolean = true
    switch (checkItem) {
      case 'age':
        const ageRegex = /^[0-9]*$/
        if (checkValue) {
          flag = ageRegex.test(checkValue)
          if (!flag) {
            if (_.indexOf(this.warrningMEssage , 'user.authority.show.age.isnumber') === -1) {
                this.warrningMEssage.push('user.authority.show.age.isnumber')
            }
         }
        }
        break
      case 'phone':
        const telRegex = /^[0-9\s-+]*$/
        if (checkValue) {
          flag = telRegex.test(checkValue)
          if (!flag) {
            if (_.indexOf(this.warrningMEssage , 'user.add_modify_draw.error_tel') === -1) {
                  this.warrningMEssage.push('user.add_modify_draw.error_tel')
                }
          }
          if (checkValue.length > 16) {
            flag = false
            if (_.indexOf(this.warrningMEssage , 'user.authority.phone.overlength') === -1) {
                this.warrningMEssage.push('user.authority.phone.overlength')
              }
          }
        }
        break
      case 'email':
        const emailRegex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z]+)+$/
        if (checkValue) {
          flag = emailRegex.test(checkValue)
          if (!flag) {
            if (_.indexOf(this.warrningMEssage , 'user.add_modify_draw.email_msg') === -1) {
                  this.warrningMEssage.push('user.add_modify_draw.email_msg')
                }
          }
          if (checkValue && checkValue.length > 45) {
            flag = false
            if (_.indexOf(this.warrningMEssage , 'user.authority.email.overlength') === -1) {
                this.warrningMEssage.push('user.authority.email.overlength')
              }
          }
        }
        break
      case 'name':
        checkValue = checkValue ? checkValue.trim() : ''
        if (checkValue.length > 32) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.name.overlength') === -1) {
              this.warrningMEssage.push('user.authority.name.overlength')
            }
        } else if (_.isEmpty(checkValue)) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.name.nullerro') === -1) {
              this.warrningMEssage.push('user.authority.name.nullerro')
            }
        }
        break
      case 'person_type':
        if (checkValue.length > 16) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.person_type.overlength') === -1) {
              this.warrningMEssage.push('user.authority.person_type.overlength')
            }
        }
        break
      case 'person_number':
        if (checkValue.length > 36) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.person_number.overlength') === -1) {
              this.warrningMEssage.push('user.authority.person_number.overlength')
            }
        }
        break
      case 'certificate_type':
        checkValue = checkValue ? checkValue.trim() : ''
        if (_.isEmpty(checkValue)) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.certificate_type.nullerro') === -1) {
              this.warrningMEssage.push('user.authority.certificate_type.nullerro')
            }
        } else if (checkValue.length > 20) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.certificate_type.overlength') === -1) {
              this.warrningMEssage.push('user.authority.certificate_type.overlength')
            }
        }
        break
      case 'certificate_number':
        checkValue = checkValue ? checkValue.trim() : ''
        if (_.isEmpty(checkValue)) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.certificate_number.nullerro') === -1) {
              this.warrningMEssage.push('user.authority.certificate_number.nullerro')
            }
        } else if (checkValue.length > 32) {
          flag = false
          if (_.indexOf(this.warrningMEssage , 'user.authority.certificate_number.overlength') === -1) {
              this.warrningMEssage.push('user.authority.certificate_number.overlength')
            }
        }
        break
      default:
        flag = false
        break
    }
    return  flag
  }
  public tableEditModeFun() {
    if (this.$store.state.main.userAuthorityShow.hadEditFlag) {
      this.editConformFlag = true
    } else {
        this.tableEditModeOff = !this.tableEditModeOff
        if (!this.tableEditModeOff) {
          this.tableSaveFlag = 'person'
        } else {
        this.tableSaveFlag = ''
      }
    }
  }
  // 表格确认修改，重置修改
  public headButAction(actionData) {
    if (actionData) {
      switch (actionData.action) {
        case 'save':
          this.afterEditCsvPush()
          this.resetSelectAllCheckBox(null)
          break
        case 'saveLock':
          this.afterEditCsvPush()
          if (this.afterTableEditDataShow.length === 0) {
            this.tableEditModeOff = true
            this.tableSaveFlag = ''
            this.saveLockFlag = false
            // 保存后初始化变动记录
            this.afterTableEditDataShow = []
            this.selected = []
            this.deleteIDList = []
            this.tableEditModeOff = true
            this.tableSaveFlag = ''
          }
          this.saveLockFlag = true
          break
        case 'reset':
          this.resetConformFlag = true
          break
        case 'addTableRow':
          this.addTableRow()
          this.resetSelectAllCheckBox(null)
          break
        case 'showUpLoadModel':
          this.csvUpLoadModelShow = true
          break
        case 'rowDeleteAll':
          this.rowDeleteAll()
          break
      }
    }
  }
  public async afterEditCsvPush() {
    try {
      if (this.deleteIDList.length === 0 ) {
        if (this.afterTableEditDataShow.length === 0) {
          await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
          this.editConformFlag = false
          this.afterTableEditDataShow = []
          this.csvCoverUpdate = false
          this.csvFileList = []
          this.userSourceData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
          this.userSourceData = [...this.userSourceData]
          this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
          this.getUserListData = [...this.getUserListData]
          this.$store.state.main.userAuthorityShow.hadEditFlag = false
          this.$message.success(this.$i18n.tc('user.authority.save.success'))
        }
      }
      if (this.deleteIDList.length > 0) {
        if (this.afterTableEditDataShow.length === 0) {
          await logicalDeleteAll(this.theRanterIDs, this.deleteIDList)
          await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
          this.editConformFlag = false
          this.afterTableEditDataShow = []
          this.csvCoverUpdate = false
          this.csvFileList = []
          this.userSourceData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
          this.userSourceData = [...this.userSourceData]
          this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
          this.getUserListData = [...this.getUserListData]
          this.$store.state.main.userAuthorityShow.hadEditFlag = false
          this.$message.success(this.$i18n.tc('user.authority.save.success'))
        }
      }
      if (this.afterTableEditDataShow.length > 0) {
        this.editConformFlag = true
      }
    } catch {
      return
    }
  }
  // table editor function END
  // 绑定人员到设备弹窗
  public async deviceDetailClose(returnData) {
    this.deviceDetailDrawFlag = false
    if (returnData) {
      await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
      this.usersDataLength = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData.length)
      this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
    }
  }
  public async csvUpLoadModelOK(returnData) {
    // csv 上传方法
    if (returnData.close) {
      // 取消上传
      this.csvUpLoadModelShow = false
    } else {
      this.csvUpLoadModelShow = false
      // 确认上传
      commitSetLoading(this.$store, true)
      await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
      this.usersDataLength = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData.length)
      this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
      // 取得csv文件上传后返回的list
      commitSetLoading(this.$store, false)
    }
  }
  public getORGIDs(organizationNames) {
    let orgID: any[] = []
    const enterpriseHashGlobalData : any = JSON.parse(sessionStorage.getItem('getEnterpriseHashData') || '')
    const enterpriseHashData = _.find(enterpriseHashGlobalData , {id : parseInt(this.theRanterIDs || '0', 10)})
    let rootBindFlag : boolean = false // 绑定了根组织
    if (organizationNames) {
        let theORg : any  = this.treeEnterprise
        let orgResult : any = {}
        // 按 分号； 分割成数组
        _.words(organizationNames, /[^; ]+/g).forEach((element : any) => {
          // 按 斜杠/ 分割成数组
            if (element.indexOf('/') === -1) {
              rootBindFlag = true
            }
            _.words(element, /[^/ ]+/g).forEach((orgName : any , index : any) => {
                  if (index === 0) {
                    // 根组织验证
                    if (_.find(this.treeEnterprise , {name : orgName})) {
                      theORg = _.find(this.treeEnterprise , {name : orgName}).children
                    } else {
                      // 根组织错误
                      orgID.push({ id : this.theRanterIDs, name : enterpriseHashData.name})
                      theORg = []
                    }
                  } else {
                    // 子组织验证
                    if (_.find(theORg , {name : orgName})) {
                      orgResult = _.find(theORg , {name : orgName})
                      theORg = orgResult.children
                    } else {
                      // 子组织错误，不存在该组织
                      orgID.push({ id : this.theRanterIDs, name : enterpriseHashData.name})
                    }
                  }
            })
            // 存在一个正确的org信息时绑定该org
            orgID =  _.differenceWith(orgID , [{ id : this.theRanterIDs, name : enterpriseHashData.name}] , _.isEqual)
            if (orgResult.id) {
                orgID.push({ id : orgResult.id, name : orgResult.name})
            }
            orgResult = {}
            if (orgID.length === 0) {
              // 没有正确的org去绑定
              orgID.push({ id : this.theRanterIDs , name : enterpriseHashData.name})
            }
        })
    }
    if (rootBindFlag) {
      orgID =  _.differenceWith(orgID , [{ id : this.theRanterIDs, name : enterpriseHashData.name}] , _.isEqual)
      orgID.push({ id : this.theRanterIDs , name : enterpriseHashData.name})
    }
    return orgID
  }
  // 表格排序
  public sortChangeAction(peops) {
    this.sortProps = peops.detail.column.prop
    this.sortRule = peops.detail.order
  }
  // CSV导出方式
  public async csvFilesOut(doc) {
    await downloadDataZip({sortField : this.sortProps || 'name' , sortOrder : this.sortRule || 'desc'}
    , this.theRanterIDs)
    // 定义文件内容，类型必须为Blob 否则createObjectURL会报错
    const content = new Blob([await this.$store.state.main.messagePro])
    // 生成url对象
    const urlObject: any = window.URL || window.webkitURL || window
    const url = urlObject.createObjectURL(content)
    // 生成<a></a>DOM元素
    const el = document.createElement('a')
    // 链接赋值
    el.href = url
    el.download = this.$i18n.tc('csv_export_filename') + '_person.xlsx'
    // 必须点击否则不会下载
    el.click()
    // 移除链接释放资源
    urlObject.revokeObjectURL(url)
  }
  public rowDeleteAll() {
    this.setSelectedData()
    if (this.tableEditModeOff) {
      this.$message.error(this.$i18n.tc('user.authority.use_edit_mode_msg'))
    } else {
      if (this.selected.length > 0) {
        this.modelVisible = !this.modelVisible
      }
    }
  }
  // delete row selected model
  public modelHandleOk(e) {
    commitSetLoading(this.$store, true)
    // deleteAll(this.userType, ) // rowIDSelect;回传删除的ID数组
    this.selected.map((obj : any) =>  {
      this.deleteIDList.push(obj.id)
      _.remove(this.getUserListData, (o : any) => obj.rowKey === o.rowKey)
      if (!obj.id) {
        // 删除待更新进入后台的数据
        _.remove(this.afterTableEditDataShow, (o : any) => obj.rowKey === o.rowKey)
        if (this.afterTableEditDataShow.length === 0 ) {
          this.$store.state.main.userAuthorityShow.hadEditFlag = false
        }
      }
    })
    this.getUserListData = [...this.getUserListData]
    this.modelVisible = false
    this.selected = []
    commitSetLoading(this.$store, false)
    // 删除后重置全选按钮
    this.resetSelectAllCheckBox(null)
  }
  // delete row selected END
  // edit table conform
  public async editConformOk() {
    try {
      let updateCheckFalg : boolean = true
      commitSetLoading(this.$store, true)
      if (this.deleteIDList.length > 0) {
        // 删除
        // await deleteAll(this.userType, this.deleteIDList)
        await logicalDeleteAll(this.theRanterIDs, this.deleteIDList)
      }
      if (this.afterTableEditDataShow.length > 0) {
        // 更新表格信息
        let updateData = _.cloneDeep(this.afterTableEditDataShow)
        updateData = updateData.map((data : any) => {
          updateCheckFalg = this.checkValFun('name', data.name) ? updateCheckFalg : false
          updateCheckFalg = this.checkValFun('age', data.age) ? updateCheckFalg : false
          updateCheckFalg = this.checkValFun('phone', data.phone) ? updateCheckFalg : false
          updateCheckFalg = this.checkValFun('email', data.email) ? updateCheckFalg : false
          updateCheckFalg = this.checkValFun('certificate_type', data.certificate_type) ? updateCheckFalg : false
          updateCheckFalg = this.checkValFun('certificate_number', data.certificate_number) ? updateCheckFalg : false
          if (data.org_ids) {
            data.org_ids = _.compact(data.org_ids.map((orgData: any) =>  orgData.id ? orgData.id.toString() : null))
          }
          if (!data.age) {
            data.age = 0
          }
          if (!data.gender) {
            data.gender = 0
          }
          return data
        })
        if (updateCheckFalg) {
          if (this.csvCoverUpdate) {
            // 覆盖更新
            await personInfoCover(updateData, this.theRanterIDs)
          } else {
            // 增量更新
            await updateUserInfo(updateData, this.theRanterIDs)
          }
          if (this.saveLockFlag) {
            this.tableEditModeOff = true
            this.tableSaveFlag = ''
            this.saveLockFlag = false
          }
          await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
          this.$store.state.main.userAuthorityShow.hadEditFlag = false
          this.editConformFlag = false
          this.afterTableEditDataShow = []
          this.csvCoverUpdate = false
          this.csvFileList = []
          this.userSourceData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
          this.userSourceData = [...this.userSourceData]
          this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
          this.getUserListData = [...this.getUserListData]
          this.$message.success(this.$i18n.tc('user.authority.save.success'))
        } else {
          this.warrningMEssage.forEach( (message : string) => {
            this.$message.error(this.$i18n.tc(message))
          })
          this.warrningMEssage = []
        }
      } else {
        // 未做任何更新
        await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
        this.$store.state.main.userAuthorityShow.hadEditFlag = false
        this.editConformFlag = false
        this.afterTableEditDataShow = []
        this.csvCoverUpdate = false
        this.csvFileList = []
        this.userSourceData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
        this.userSourceData = [...this.userSourceData]
        this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
        this.getUserListData = [...this.getUserListData]
        this.$message.success(this.$i18n.tc('user.authority.save.success'))
      }
      commitSetLoading(this.$store, false)
    } catch {
      commitSetLoading(this.$store, false)
      return
    }
  }
  public editConformCancel() {
    this.editConformFlag = false
  }
  public async resetConformOk() {
    commitSetLoading(this.$store, true)
    await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
    this.userSourceData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
    this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
    this.$store.state.main.userAuthorityShow.hadEditFlag = false
    commitSetLoading(this.$store, false)
    this.afterTableEditDataShow = []
    this.selected = []
    this.deleteIDList = []
    this.resetConformFlag = false
    // 退出编辑
    this.tableEditModeOff = true
    this.tableSaveFlag = ''
  }
  public resetConformCancel() {
    this.resetConformFlag = false
  }
  // add Table rows

  public async addTableRow() {
    if (this.tableEditModeOff) {
      this.$message.error(this.$i18n.tc('user.authority.use_edit_mode_msg'))
    } else {
       const addObj = {
         name: ' ',
         rowKey : -this.getUserListData.length,
         gender : '0',
         age : '0',
         certificate_type : ' ',
         }
       this.afterTableEditDataShow.push(addObj)
       this.getUserListData.push(addObj)
       this.$store.state.main.userAuthorityShow.hadEditFlag = true
       this.getUserListData = [...this.getUserListData]
     }
  }
  // add Table rows END
  // edit table conform END

  // VUE 生命周期
  private async  created() {
    this.filterGt.extra = 'input'
    this.filtercontains.extra = 'input'
    await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.theRanterIDs)
    this.treeEnterprise = await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    this.userSourceData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
    this.usersDataLength = this.$store.state.main.userAuthorityShow.userData.length
    this.getUserListData = _.cloneDeep(this.$store.state.main.userAuthorityShow.userData)
  }
  // 恢复全选框当前状态
  public resetSelectAllCheckBox(flag) {
    const itemAll: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxAll')
    const isCheck = itemAll[0]['checked']
    const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxItem')
    _.forEach(itemCheck, (item : any) => {
      try {
        item['checked'] = isCheck
        if (isCheck) {
          const idx = parseInt(item.getAttribute('rIdx'), 0)
          let checkData = _.find(this.getUserListData, { rowKey: idx })
          checkData = checkData ? checkData : _.find(this.getUserListData, { rowKey: -idx })
          if (checkData) {
            this.selected = _.unionBy(this.selected, [checkData], 'rowKey')
          }
        }
      } catch (e) {
        // ingore
      }
    })
    if (!isCheck) {
      this.selected = []
    }
    const that = this
    if (!flag) {
      setTimeout(() => {
        that.resetSelectAllCheckBox(1)
      }, 100)
    }
  }
  // 重新获取所有的勾选的行信息
  public setSelectedData() {
    const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.checkBoxItem')
    const currentSelected: any = []
    const getUserListData: any = this.getUserListData
    _.forEach(itemCheck, (item : HTMLElement) => {
      try {
        const isCheck = item['checked']
        if (isCheck) {
          const nextNode = (item as any).parentNode.parentNode.children[1] as HTMLElement
          const idx = parseInt(nextNode.innerText, 0)
          let checkData = _.find(getUserListData, { rowKey: idx })
          checkData = checkData ? checkData : _.find(getUserListData, { rowKey: -idx })
          if (checkData) {
            currentSelected.push(checkData)
          }
        }
      } catch (e) {
        console.error(e)
      }
    })
    this.selected = currentSelected
  }
  // 获取真实选中的详情信息
  public getRealUserChoseDetail(rIdx) {
    const itemCheck: NodeListOf<HTMLElement> = document.querySelectorAll('.lockEdit')
    const userListData: any = [...this.getUserListData]
    let choseData: any = null
    _.forEach(itemCheck, (item : HTMLElement) => {
      try {
        const idx = parseInt((item as any).getAttribute('rowIndex'), 0)
        if (rIdx === idx) {
          const nextNode = (item as any).parentNode.parentNode.children[1] as HTMLElement
          const id = parseInt(nextNode.innerText, 0)
          let checkData = _.find(userListData, { rowKey: id })
          checkData = checkData ? checkData : _.find(userListData, { rowKey: -id })
          if (checkData) {
            choseData = checkData
          }
        }
      } catch (e) {
        console.error(e)
      }
    })
    return choseData
  }
}
