




















































import { Vue, Component, Watch } from 'vue-property-decorator'
import {
  updateInfo,
  getRanterTreeData,
  uploadImage,
  getImageByORG,
} from '../../../../interfaces/service/org/enterpriseMang'
import _ from 'lodash'
import { getBase64 } from '../../../../interfaces/service/person/personMang'
import { commitSetLoading } from '../../../../store/main/mutations'
import {to} from '@/utils/util'
@Component({
  components: {},
  data() {
    return {}
  },
})
export default class UpdateCompany extends Vue {
  public theEname: string = ''
  public userDescripton: string = ''

  // 头像上传
  public previewVisible: boolean = false
  public previewImage: string = ''
  public fileList: any = [] // 头像文件数组
  public avatarFile: string = '' // 上传头像后用于绑定user
  public subLoading: boolean = false
  public fileStatus: boolean = false
  public theORGInfo : any = {} // 获取的企业信息
  // public submitDisFlag : boolean = true // 未做修改时不可提交
  public theRanterId = sessionStorage.getItem('ranterId')
  public theWindowW = document.body.clientWidth
  public theWindowH = document.body.clientHeight
  @Watch('$route') public async dataFreash() {
    // 路由切换刷新数据
    await this.dataFresh()
  }
  @Watch('$store.state.main.screen.width') public windowWeight() {
    this.theWindowW = document.body.clientWidth
  }
  @Watch('$store.state.main.screen.height') public windowHeight() {
    this.theWindowH = document.body.clientHeight
  }
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }
  @Watch('theRanterId')
  public async flashORGData() {
    await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    const orgInfo : any = _.compact(await this.$store.state.main.enterprise.enterpriseData.map((orgData: any) => {
       if (orgData.id.toString() === this.theRanterId) {
           return orgData
       }
     }))
    this.getRanterAvatar(this.theRanterId, orgInfo[0].avatar)
    this.theORGInfo = orgInfo[0]
    this.fileList = []
    await this.setOrgData(orgInfo[0].name , orgInfo[0].description)
  }
  public async modelSubmit() {
    if (this.theEname) {
      commitSetLoading(this.$store, true)
      let err
      let result
      [err, result] = await to(updateInfo( {
        id: this.theRanterId,
        parent_id: 0,
        name: this.theEname,
        avatar: this.avatarFile,
        description: this.userDescripton,
        sort: 0
      }, this.$store.state.main.userProfile.user_type))
      commitSetLoading(this.$store, false)
      if (err) {
        return
      }
      this.$message.success(this.$i18n.tc('summary.update.company.update.submit'))
      this.$router.push({
        path: '/main/guider-nav-page/summary/',
        query: { ranterName: this.theEname }}).catch(() => {this.theEname = this.theEname})
      localStorage.setItem('ranterNameChose', this.theEname)
      localStorage.setItem('ranterAvatarUrlChose', this.avatarFile)
      this.$store.commit('setRanterName', this.theEname)
      this.$store.commit('setRanterAvatarUrl', this.avatarFile)
    } else {
      this.$message.error(this.$i18n.tc('enterprise.new_enterprise.name_msg'))
      return
    }
  }
  public async avatarPreview(file) {
    // 头像预览
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }
  public beforeUpload(file) {
    // 文件check
    this.imgUpLoadCheck(file)
    return false
  }

  public imgUpLoadCheck(file) {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
     if (file.size > 2048000) {
        // 大于2MB
        this.$message.error(this.$i18n.tc('enterprise.new_enterprise.error_more_than_2m'))
        this.fileStatus = false
      }
    } else {
      // 非一般图片格式
      this.$message.error(this.$i18n.tc('enterprise.new_enterprise.error_jpg_only'))
      this.fileStatus = false
    }
    this.fileStatus = true
  }
  // public editChange() {
  //   this.submitDisFlag = false
  // }
  public async avatarChange(fileList) {
    // this.editChange()
    // 只有新加图片并且检证OK的时候才上传
    if (this.fileStatus) {
      this.fileStatus = false
      // 每次点击上传头像时，传入后台，取得后台的Uid与序号（序号为数组序号）
      await uploadImage({
        // 上传头像
        upload_file: fileList.file,
        upload_seq: this.fileList.length + 1,
        org_id: this.theRanterId,
      })
      const imageInfo = this.$store.state.main.messagePro
      this.avatarFile = imageInfo.avatar
      this.fileList = fileList.fileList
    }
  }
  // 删掉上传的图片
  public handleRemove(file) {
    const index = this.fileList.indexOf(file)
    const newFileList = this.fileList.slice()
    newFileList.splice(index, 1)
    this.fileList = newFileList
    this.avatarFile = '' // 删除用于绑定的图片url
  }

  public avatarCancel() {
    // 关闭预览
    this.previewVisible = false
  }
  // 头像上传END
  /**
   * 获取租户头像
   * @param {number} ranterId 租户id
   * @param {url} string 该租户的头像url地址
   */
  private async getRanterAvatar(ranterId, url) {
    if (!url) {
      return
    }
    await getImageByORG(ranterId, url)
    this.avatarFile = url
    this.fileList = []
    const ranterAvatarUrl : any = await getBase64(this.$store.state.main.messagePro)
    const imgFile : any = {
                    uid: '000',
                    name: 'RanterShow.png',
                    status: 'done',
                    url: ranterAvatarUrl,
                     }
    this.fileList.push(imgFile)
  }
  private setOrgData(name , description) {
    this.theEname = name
    this.userDescripton = description
  }
  public async dataFresh() {
    await getRanterTreeData(this.$store.state.main.userProfile.user_type)
    const orgInfo : any = _.compact(await this.$store.state.main.enterprise.enterpriseData.map((orgData: any) => {
    if (orgData.id.toString() === this.theRanterId) {
          return orgData
      }
    }))
    await this.getRanterAvatar(this.theRanterId, orgInfo[0].avatar)
    this.theORGInfo = orgInfo[0]
    await this.setOrgData(orgInfo[0].name , orgInfo[0].description)
    this.theRanterId = sessionStorage.getItem('ranterId')
  }
  private async created() {
    await this.dataFresh()
  }
}
